.jumbotron {
  background-image: linear-gradient(
      180deg,
      rgba(var(--jumbotron-background-color), 0.01),
      rgba(var(--jumbotron-background-color), 1) 85%
    ),
    radial-gradient(
      ellipse at top left,
      var(--jumbotron-gradient-colors-0),
      transparent 50%
    ),
    radial-gradient(
      ellipse at top right,
      var(--jumbotron-gradient-colors-1),
      transparent 50%
    ),
    radial-gradient(
      ellipse at center right,
      var(--jumbotron-gradient-colors-2),
      transparent 50%
    ),
    radial-gradient(
      ellipse at center left,
      var(--jumbotron-gradient-colors-3),
      transparent 50%
    );
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--jumbotron-border-color);
}
